<template>
    <router-view/>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'

export default {
  components: {
    LayoutVertical,
  }
}
</script>
